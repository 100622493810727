<template>
  <div class='VisitOwnerMgrForm-container' :key="vdomId">
    <tabs-panel :tabs="tabs" :tabRouter="false" :before="getDetailData" :activeLabel.sync="activeLabel"></tabs-panel>
  </div>
</template>
<script>
import { TabsPanel } from 'components/bussiness'
import BaseInfoForm from './BaseInfoForm'
import questions from './questions'
import * as allVisitOwnerFromUrl from './api'
export default {
  name: 'VisitOwnerMgrForm',
  components: {
    TabsPanel
  },
  props:{
    editQuery:Object
  },
  data () {
    return {
      activeName: 'baseInfoForm',
      isEdit:false,
      visitConfig: {
        data: ''
      },
      userId: {
        id: ''
      },
      houseId: {
        id: ''
      },
      id:'',
      tabs: [],
      activeLabel: '基础信息',
      communityIdOps: [],
      vdomId: 1,
      query:{},
      isCopy:false
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    let { isEdit,isCopy } = this.query
    this.isEdit = isEdit
    this.isCopy = isCopy
    this.tabs = [
      {
        label: '基础信息',
        component: BaseInfoForm,
        props: {
          query:this.query,
          visitOwnerConfig: this.visitConfig,
          isEdit:isEdit
        },
        disabled:false
      }, {
        label: '问题设置',
        component: questions,
        props: {
          surveyData:{
            id: this.query.id,
            isEdit:isEdit,
            query:this.query,
            isCopy:this.isCopy,
            visitOwnerConfig: this.visitConfig,
            questions:[]
          }
        },
        disabled:true
      }
    ]

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'BaseInfoForm') {
        vm.activeLabel = '基础信息'
      } else if (from.name === 'questions') {
        vm.activeLabel = '问题设置'
      }
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.vdomId++ // 让页面在相同路由情况下重新加载
    next()
  },
  methods: {
    setActiveLabel (data) {
      this.activeLabel = data.label
      this.tabs[1].disabled = false
      console.log("切换tab"+JSON.stringify(data))
      this.tabs[1].props.surveyData.id = data.propData
      this.tabs[1].props.surveyData.isEdit = this.isEdit
      this.tabs[1].props.surveyData.isCopy = this.isCopy
    },
    getDetailData (next) {

      let { id } = this.query
      if (id) {
        let url = allVisitOwnerFromUrl.getVisitTempDetailDataURL
        this.$axios.get(url, {
          params: {
            id: id
          }
        }).then(res => {
            let returnStatus = String(res.status)
            if (returnStatus === '100') {
              this.visitConfig.data =  res.data
              if (res.data.questions.length > 0) {
                res.data.questions.map(item => {
                  item.noRequired = item.required === 0
                  item.isSupplement = item.isSupplement === 1
                })
                // 编辑时要判断问题长度设置按钮的disabeld的状态，如果是复制则不进行初始状态的判断，直接置为true
                this.tabs[1].disabled = res.data.questions.length < 2 || this.$route.query.isCopy
              } else {
                this.tabs[1].disabled = true
              }
              if (res.data.state === 0 && !this.$route.query.isCopy) {
                // 复制的时候不能隐藏掉问题设置按钮
                this.tabs.pop()
              }
              next()
            }
          })
      } else {
        next()
      }
    }
  }
}
</script>
