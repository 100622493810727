<template>
  <div>
    <form-panel ref="question" :form="question" class="question-wrapper" v-if="isEdit">
      <el-form-item :label="question.optionType === 4 ? '附件名称' : '拜访问题'+ (index+1)" :rules="[{ required: true, message: '请输入投票题目', trigger: 'blur' }]" prop="questionDes">
        <v-input :placeholder="question.optionType === 4 ? '' : '请输入拜访问题题目'" :maxlength="150" v-model="question.questionDes" :width="450"></v-input>
        <v-checkbox type="default" v-if="question.noRequired !== undefined && question.optionType !== 4" v-model="question.noRequired" label="选填题" class="checkbox"></v-checkbox>
      </el-form-item>

      <div v-if="question.optionType !== 3 && question.optionType !== 4">
        <el-form-item :label="idx === 0 ? '问题选项':''" v-for="(option,idx) in question.options" :key="'option' + idx" required>
          <el-form-item style="display:inline-block" :rules="[{ required: question.optionType !== 3, message: '请输入选项', trigger: 'blur' }]" :prop="`options.${idx}.optionDes`">
            <v-input placeholder="请输入选项" :maxlength="150" v-model="option.optionDes" :width="250"></v-input>
          </el-form-item>
          <v-button type="default" icon="el-icon-close" class="delOpt" circle @click="delOps(idx)" v-show="question.options.length > 2"></v-button>
        </el-form-item>
        <el-form-item>
          <v-button @click="addOps" :width="250" plain>+新增选项</v-button>
        </el-form-item>

      </div>
      <el-form-item :label="question.optionType === 2 ? '选项模式' : ''">
        <div class="multi-modal">
          <div v-if="question.optionType === 2">
            至少选
            <el-form-item style="display:inline-block" :rules="[{ required: question.optionType === 2, message: '请输入最小选项个数', trigger: 'blur' }]" prop="minSelectCnt">
              <v-input-number v-model="question.minSelectCnt" class="optPadding" :width="100" :controls="true" controls-position="right" :min="minSelectCnt" :max="question.maxSelectCnt" />
            </el-form-item> 项，至多选
            <el-form-item style="display:inline-block" :rules="[{ required: question.optionType === 2, message: '请输入最大选项个数', trigger: 'blur' }]" prop="maxSelectCnt">
              <v-input-number v-model="question.maxSelectCnt" class="optPadding" :width="100" :controls="true" controls-position="right" :min="question.minSelectCnt" :max="maxSelectCnt" />
            </el-form-item>
            项
          </div>
          <v-button type="danger" @click="delQuestion" round plain icon="el-icon-delete" :class="question.optionType === 2 ? 'delQue-multi':'delQue-single'">删除该问题</v-button>
        </div>
      </el-form-item>

    </form-panel>
    <form-panel :form="question" class="question-wrapper" v-if="!isEdit">
      <el-form-item :label="'投票问题'+ (index+1)">
        <span class="input-control">{{ question.questionDes }}</span>
        <span>{{ question.noRequired ? '(选填题)' : '(必填题)'}}</span>
      </el-form-item>
      <div v-if="question.optionType !== 3 && question.optionType !== 4">
        <el-form-item :label="index === 0 ? '问题选项':''" v-for="(option,index) in question.options" :key="'option' + index">
          <span class="input-control">{{ option.optionDes }}</span>
          <div v-if='option.associatedSorts && option.associatedSorts.length > 0' style='color: #1b8cf2;display:inline-block;margin-left:10px;'>
            [选择后显示
            <label v-for="(associated,assIdx) in option.associatedSorts" :key="'associatedSorts_'+assIdx">
              <span>问题{{associated+1}}{{option.associatedSorts.length === assIdx+1 ?'':'、'}}</span>
            </label>]
          </div>
        </el-form-item>
        <el-form-item label="问题补充" v-if="question.supplementPlaceholder">
          <span class="input-control">{{ question.supplementPlaceholder }}</span>
        </el-form-item>
        <el-form-item :label="question.optionType === 1 ?'':'选项模式'">
          <div class="multi-modal">
            <div v-if="question.optionType === 2">
              至少选
              <span class="input-control">{{ question.minSelectCnt }}</span> 项，至多选
              <span class="input-control">{{ question.maxSelectCnt }}</span>项
            </div>
          </div>
        </el-form-item>
      </div>


    </form-panel>
  </div>
</template>

<script>
import {vCheckbox, vUploader2} from 'components/control'
export default {
  name: 'vote-question',
  components: {
    vUploader2,
    vCheckbox
  },
  props: {
    question: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
    index: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      dafault: true
    },
    uploadUrl:{
      type: String,
      default: ''
    },
  },
  data () {
    return {
      minSelectCnt: 1,
      fileList:[]
    }
  },
  computed: {
    maxSelectCnt () {
      return this.question.options.length
    }
  },
  methods: {
    async beforeRemove() {
      // 上传文件删除前
      const isOk = await this.$confirm("此操作将永久删除该文件, 是否继续？");
      return isOk;
    },
    addOps () {

      let hasScore = true
      if (this.question.options.length > 0) {
        this.question.options.forEach(option => {
          hasScore = option.hasOwnProperty('score')
        })
      }
      if (hasScore) {
        this.question.options.push({ optionDes: '', score: '' })
      } else {
        this.question.options.push({ optionDes: '' })
      }
    },
    delOps (index) {
      this.question.options.splice(index, 1)
    },
    delQuestion () {
      this.$emit('delQue', this.index)
    }
  },
  watch: {
    maxSelectCnt: function (newVal, oldVal) {
      this.question.maxSelectCnt = this.question.maxSelectCnt > newVal ? newVal : this.question.maxSelectCnt
    }
  }
}
</script>

<style lang="scss" scoped>
.question-wrapper {
  .checkbox {
    display: inline-block;
    padding-left: 10px;
  }
  .optPadding {
    padding: 0 10px;
    display: inline-block;
  }
  .v-button-container .el-button {
    min-width: 32px;
  }
  .multi-modal {
    display: flex;
  }
  .delOpt {
    margin-left: 10px;
  }
  .delQue-single {
    padding-left: 400px
  }
  .delQue-multi {
    padding-left: 10px
  }
  .input-control {
    display: inline-block;
    line-height: 36px;
    padding-right: 15px;
    background-color: #FFF;
    background-image: none;
    color: #333;
    font-weight: 700;
  }
}
</style>
