var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfoForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            attrs: {
              form: _vm.form,
              submitContentType: _vm.submitContentType,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccess,
              submitText: "下一步",
            },
            scopedSlots: _vm._u([
              {
                key: "headerSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      attrs: { text: "返回" },
                      on: { click: _vm.goBack },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "拜访模版名称",
                  rules: [
                    {
                      required: true,
                      message: "请输入拜访模版名称",
                      trigger: "blur",
                    },
                  ],
                  prop: "tempName",
                },
              },
              [
                _c("v-input", {
                  model: {
                    value: _vm.form.tempName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "tempName", $$v)
                    },
                    expression: "form.tempName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "拜访类型",
                  rules: [
                    {
                      required: true,
                      message: "请选择拜访类型",
                      trigger: "change",
                    },
                  ],
                  prop: "visitType",
                },
              },
              [
                _c("v-select", {
                  attrs: { options: _vm.visitTypeList },
                  model: {
                    value: _vm.form.visitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "visitType", $$v)
                    },
                    expression: "form.visitType",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "拜访方式",
                  rules: [
                    {
                      required: true,
                      message: "请选择拜访方式",
                      trigger: "change",
                    },
                  ],
                  prop: "visitWay",
                },
              },
              [
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.form.visitWay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "visitWay", $$v)
                      },
                      expression: "form.visitWay",
                    },
                  },
                  _vm._l(_vm.visitWayList, function (visitWay) {
                    return _c(
                      "el-checkbox",
                      { key: visitWay.value, attrs: { label: visitWay.value } },
                      [_vm._v(_vm._s(visitWay.text))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }