<template>
  <form-panel ref="form"
              class="vote-question-wrapper">
    <vote-question ref="questionForm"
                   v-for="(question,index) in surveyData.questions"
                   :key="index"
                   :question="question"
                   :index="index"
                   :uploadUrl="uploadUrl"
                   @delQue="delQustion"
                   :isEdit="isEdit"></vote-question>

    <el-row type="flex"
            class="row-bg"
            justify="space-between"
            >
      <el-col :span="3">
        <v-button @click="addQustion(1)">添加单选题</v-button>
      </el-col>
      <el-col :span="3">
        <v-button @click="addQustion(2)">添加多选题</v-button>
      </el-col>
      <el-col :span="3">
        <v-button @click="addQustion(3)">添加填空题</v-button>
      </el-col>
      <el-col :span="3">
        <v-button @click="addQustion(4)">添加上传附件</v-button>
      </el-col>
    </el-row>
    <template #footerSlot>
      <v-button type="success"
                @click="publishSurvey"
                v-show="surveyData.questions.length > 0 ">保存</v-button>
      <v-button plain
                @click="saveQuestion(1)"
                v-show="surveyData.questions.length > 0 ">预览</v-button>
      <v-button type="success"
                @click="closeSurvey"
                v-show="surveyData.state === 1">关闭</v-button>
    </template>
    <mobile-preview v-if="isPreviewShow"
                    :isShow.sync="isPreviewShow"
                    :iframeUrl='iframeUrl'></mobile-preview>
  </form-panel>
</template>

<script>
import voteQuestion from 'components/bussiness/vote-question-v2/index'
import { uploadURL,votePreviewURL,saveQueURL } from './api'
import { MobilePreview } from 'components/bussiness'
import _ from 'lodash'
const questionModal = {
  questionDes: '',
  noRequired: false, // false为必填 true 为选填
  optionType: 1,
  options: [{
    optionDes: '',
    score: ''
  }, {
    optionDes: '',
    score: ''
  }],
  supplementPlaceholder: '',
  isSupplement: false
}
export default {
  name: 'visitOwnerConfig-questions',
  components: {
    voteQuestion,
    MobilePreview
  },
  props: {
    surveyData:{
      questions:[]
    }
  },
  data () {
    return {
      isEdit: true,
      validata: true,
      validataMsg: '',
      questionData: [],
      isPreviewShow: false,
      iframeUrl: 'http://m.baidu.com',
      uploadUrl:uploadURL
    }
  },
  created () {
    console.log(this.surveyData)
    if (this.surveyData.isEdit || this.$route.query.isCopy) {
      this.isEdit = true
      this.surveyData.questions = this.surveyData.visitOwnerConfig.data.questions
    }

    this.iframeUrl = '/thH5/#/pages/visit/visitQuestion?tempId=' + this.surveyData.id
  },
  methods: {
    addQustion (optionType) {
      let que = _.cloneDeep(questionModal)
      que.optionType = optionType
      if (optionType === 4) {
        que.options = []
      }
      this.surveyData.questions.push(que)
      console.log(this.surveyData.questions)
    },
    async delQustion (index) {
      // 如果这道题目 被其他选项关联 ，删除选项中的关联
      let isOk = await this.$confirm('删除题目后，会清除所有显示关联，确定删除？')
      if (isOk) {
        let questions = this.surveyData.questions
        if (questions.length) {
          questions.forEach(question => {
            question.beAssociated = 0
            if (question.options.length > 0) {
              question.options.forEach(option => {
                if (option.associatedSorts) {
                  if (option.associatedSorts.length > 0) {
                    option.associatedSorts = []
                  }
                }
              })
            }
          })
        }
        this.surveyData.questions.splice(index, 1)
      }
    },
    validateQuestion () {
      let questions = this.surveyData.questions
      this.validata = true
      this.validataMsg = ''
      let _this_ = this
      let requiredNum = 0
      if (questions.length > 0) {
        _this_.questionData = []
        questions.forEach(que => {
          que.required = que.noRequired ? 0 : 1
          if (que.required === 1) {
            requiredNum++
          }
          if (que.optionType === 3) {
            que.options = []
          }
          _this_.questionData.push(que)
        })
      }
      if (requiredNum === 0) {
        this.validata = false
        this.validataMsg = '问卷至少要有一道必填题目'
      }
    },
    saveQuestion (isPreviewShow) {
      let validata = true
      let voteQuestions = this.$refs['questionForm']
      if (voteQuestions.length > 0) {
        voteQuestions.forEach(question => {
          validata = question.$refs.question.validate()
        })
      }
      this.validateQuestion()
      if (validata) {
        if (this.validata) {
          let postData = {
            visitTempId: this.surveyData.id,
            questions: this.questionData
          }
          let _this_ = this
          _this_.$axios({
            url: saveQueURL,
            method: 'post',
            data: postData
          }).then(res => {
            if (res.status === 100) {
              // 预览
              _this_.isPreviewShow = isPreviewShow ? true : false
              _this_.$message.success('保存成功')

            }else {
               _this_.$message.error(res.message)
            }
          })
        } else {
          this.$alert(this.validataMsg)
        }
      }
    },
    async publishSurvey () {
      let isOk = await this.$confirm('温馨提示：发布后无法修改问题信息，可先点击预览按钮查看页面效果！')
      if (isOk) {
        let _this_ = this
        _this_.saveQuestion(0);
        _this_.$router.go(-1)
      }
    },
    async closeSurvey () {
      let isOk = await this.$confirm('是否要关闭调查问卷！')
      if (isOk) {
        let _this_ = this
        _this_.$axios.put(closeURL, _this_.$qs.stringify({ id: _this_.surveyData.id })).then(res => {
          if (res.status === 100) {
            _this_.$message.success('关闭成功')
            _this_.$router.go(-1)
          }
        })
      }
    },
  },
  watch: {
    'surveyData.questions.length': {
      handler (newVal, oldVal) {
        let disabled = true
        if (newVal >= 2) {
          disabled = false
        } else {
          disabled = true
        }

      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vote-question-wrapper {
  .row-bg {
    margin-left: 88px;
    width: 700px;
  }
}
</style>
