var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-panel",
    {
      ref: "form",
      staticClass: "vote-question-wrapper",
      scopedSlots: _vm._u([
        {
          key: "footerSlot",
          fn: function () {
            return [
              _c(
                "v-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.surveyData.questions.length > 0,
                      expression: "surveyData.questions.length > 0 ",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.publishSurvey },
                },
                [_vm._v("保存")]
              ),
              _c(
                "v-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.surveyData.questions.length > 0,
                      expression: "surveyData.questions.length > 0 ",
                    },
                  ],
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.saveQuestion(1)
                    },
                  },
                },
                [_vm._v("预览")]
              ),
              _c(
                "v-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.surveyData.state === 1,
                      expression: "surveyData.state === 1",
                    },
                  ],
                  attrs: { type: "success" },
                  on: { click: _vm.closeSurvey },
                },
                [_vm._v("关闭")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._l(_vm.surveyData.questions, function (question, index) {
        return _c("vote-question", {
          key: index,
          ref: "questionForm",
          refInFor: true,
          attrs: {
            question: question,
            index: index,
            uploadUrl: _vm.uploadUrl,
            isEdit: _vm.isEdit,
          },
          on: { delQue: _vm.delQustion },
        })
      }),
      _c(
        "el-row",
        {
          staticClass: "row-bg",
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "v-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addQustion(1)
                    },
                  },
                },
                [_vm._v("添加单选题")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "v-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addQustion(2)
                    },
                  },
                },
                [_vm._v("添加多选题")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "v-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addQustion(3)
                    },
                  },
                },
                [_vm._v("添加填空题")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "v-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addQustion(4)
                    },
                  },
                },
                [_vm._v("添加上传附件")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isPreviewShow
        ? _c("mobile-preview", {
            attrs: { isShow: _vm.isPreviewShow, iframeUrl: _vm.iframeUrl },
            on: {
              "update:isShow": function ($event) {
                _vm.isPreviewShow = $event
              },
              "update:is-show": function ($event) {
                _vm.isPreviewShow = $event
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }