<template>
  <div class='baseInfoForm-container'>
    <form-panel v-bind="submitConfig" :form="form"  :submitContentType="submitContentType"
      :submitBefore="submitBefore" :submitSuccess="submitSuccess" submitText="下一步">
      <template #headerSlot>
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <template>
        <el-form-item label="拜访模版名称" :rules="[{ required: true, message: '请输入拜访模版名称', trigger: 'blur' }]" prop="tempName">
          <v-input v-model="form.tempName"></v-input>
        </el-form-item>
        <el-form-item label="拜访类型" :rules="[{ required: true, message: '请选择拜访类型', trigger: 'change' }]" prop="visitType">
          <v-select v-model="form.visitType" :options="visitTypeList"></v-select>
        </el-form-item>
        <el-form-item label="拜访方式" :rules="[{ required: true, message: '请选择拜访方式', trigger: 'change' }]" prop="visitWay">
          <el-checkbox-group v-model="form.visitWay">
            <el-checkbox v-for="visitWay in visitWayList" :label="visitWay.value" :key="visitWay.value">{{visitWay.text}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </template>
    </form-panel>
  </div>
</template>
<script>
import * as allOwnerMgrFormUrl from './api'
import vPreview from 'components/control/v-preview/v-preview'
import { ChosenListPanel, MultiSelect, Col2Block, Col2Detail, Col2Item } from 'components/bussiness'
import * as commonData from './map'
export default {
  name: 'BaseInfoForm',
  components: {
    MultiSelect,
    vPreview,
    ChosenListPanel,
    Col2Block,
    Col2Detail,
    Col2Item
  },
  props: ['visitOwnerConfig', 'partyMemberInfo', 'query'],
  data () {
    return {
      isAdd: false,
      disabled: false,
      form: {
        tempName: '',
        visitType: 0,
        visitWay:[]
      },
      houseId: '',
      userId: '',
      cardImgs: [],
      subImgs: [],
      editShowResetPassword: false,
      showCardImgs: false,
      showSubImgs: false,
      submitConfig: {
        submitUrl: allOwnerMgrFormUrl.addBaseInfoURL,
        submitContentType: 'application/json; charset=utf-8',

        submitMethod: 'POST'
      },
      realnameExamineText: '待认证',
      labelId: [],
      isShowMultiSelect: false,
      visitTypeList: commonData.visitTypeOps,
      visitWayList: commonData.visitWayList,
      realnameExamine: commonData._realnameExamine,
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      labelTypeOps: commonData.labelTypeOps,
      statusOps: commonData.statusOps,
      categoryIdOps: [],
      multiSelectHeader: [{
        prop: 'labelName',
        label: '标签名称'
      }, {
        prop: 'category',
        label: '分类'
      }, {
        prop: 'status',
        label: '状态'
      }, {
        prop: 'inuser',
        label: '创建人'
      }, {
        prop: 'intime',
        label: '创建时间'
      }, {
        prop: 'latestJoinTime',
        label: '最新用户加入时间'
      }],
      searchelseParams: {
        labelName: '',
        labelType: undefined,
        categoryId: undefined,
        inuser: '',
        status: undefined,
        createStartTime: '',
        createEndTime: '',
        joinStartTime: '',
        joinEndTime: ''
      },
      type: '',
      fromIotImportantPersonManage: undefined,
      tempId: undefined,
      isEdit:false
    }
  },
  computed: {
    subPageType () {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal () {
      return this.subPageType == 'drawer' ? this.query : this.$route.query
    },
    submitContentType () {
      // if (this.isAdd) {
      //   return 'application/x-www-form-urlencoded;charset=UTF-8'
      // } else {
      //   return 'application/json;charset=UTF-8'
      // }
      return 'application/json;charset=UTF-8'
    },
  },
  created () {

  },
  mounted () {
    let { id, addUserId, type, isEdit } = this.queryReal
    this.tempId = id;
    this.isEdit = isEdit
    if (id || addUserId) {
      this.echoData()
    }
    this.type = type

  },
  methods: {
    async goBack () {
      let isOk = await this.$confirm(
        '您当前输入信息还未被保存，确定离开当前页面吗？'
      )
      if (isOk) {
        this.$router.go(-1)
      }
    },
    submitBefore (data) {
      data.visitWay = data.visitWay.length ? data.visitWay.join(',') : '';
      if (this.query.isCopy) {
        data.id = ''
        this.submitConfig.submitUrl= allOwnerMgrFormUrl.addBaseInfoURL;
      }
      return true
    },
    submitSuccess (data) {

      if (data) {
        console.log("tempId" + this.tempId)
        console.log("data" + data)
        console.log("this.isEdit" + this.isEdit)
        this.tempId = this.isEdit ? this.tempId : data
        this.$message.success('保存成功')
        if (this.fromIotImportantPersonManage) {
          this.$router.replace({ name: 'iotImportantPersonManage' })
        }
        // let path = this.$router.history.current.path
        // let type = this.$route.query.type
        // this.$router.push({ path, query: { addUserId: data, type: type } })
        // this.$emit('getUserId', data)
      }
      // 当基础信息保存成功后tabs为houseInfoForm
      this.$emit('contact', {
        method: 'setActiveLabel',
        data:  {
          label: '问题设置',
          propData: this.tempId? this.tempId : data
        }
      })
      return false
    },
    // 编辑数据回显
    echoData () {
      let data = this.visitOwnerConfig.data
      if (data.id) {
        this.form = {
          id: data.id,
          tempName: data.tempName,
          visitType: data.visitType,
          visitWay: data.visitWay.split(',')
        }
        this.submitConfig.submitUrl = allOwnerMgrFormUrl.updateBaseInfoURL
      }
    }
  }
}
</script>
<style scoped lang="scss">
.baseInfoForm-container {
  height: 100%;
}

</style>
